<template>
  <el-dialog :append-to-body="true" :visible.sync="dialogVisible" title="连接详情" width="900px" :before-close="close">
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="ServerId" label="服务器id"/>
      <el-table-column prop="State" label="状态">
         <template slot-scope="scope">
          <span>{{scope.row.State === 0 ? '未连接': scope.row.State === 1 ? '连接中': '连接成功'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="MaxConn" label="最大连接数"/>
      <el-table-column prop="ActiveConn" label="已用连接数"/>
      <el-table-column prop="FreeConn" label="可用连接数"/>
      <el-table-column prop="ConnectTime" label="连接时间"/>
    </el-table>
  </el-dialog>
</template>
<script>
import { initData } from '@/api/data'
import { parseTime } from '@/utils/index'
export default {
  name:'detailDialog',
  props: {
    connectData: {
      type: Object,
      required: true
    },
    dialogVisible:{
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      sup_this: this,
      loading:false,
      data:[]
    }
  },
  created() {
  },
  watch:{
    connectData(){
       if(this.connectData.type==1 || this.connectData.type==3) {
          this.init()
      }
    }
  },
  methods: {
    parseTime,
    init() {
      this.loading=true
      let url = 'community/crm/getManagerListenerInfo'
      if(this.connectData.type==1) {
         url = 'community/crm/getManagerApiInfo'
      }
      let params = { hostId:this.connectData.id};
      initData(url, params).then(res => {
          this.data = res
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
    },
    close(){
      this.loading = false,
      this.data = [],
      this.$emit("close-dialog")
    }
  }
}
</script>

<style scoped>

</style>
