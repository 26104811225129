<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-input
      style="width: 150px;"
      v-model="query.idOrName"
      clearable
      placeholder="主机ID/主机名"
      class="filter-item"
      @keyup.enter.native="toQuery" />
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
export default {
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('query-data');
    }
  }
}
</script>
<style scoped lang="less">
  .align-top {
    vertical-align: top;
  }
</style>
