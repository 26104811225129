<template>
  <div class="app-container">
    <eHeader @query-data="queryDataHandle" :query="query"/>
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      stripe
      size="small"
      style="width: 100%;"
      border
    >
      <el-table-column prop="id" label="主机ID"/>
      <el-table-column prop="type" label="主机类型">
        <template slot-scope="scope">
          <span>{{scope.row.type === 1 ? 'ManagerApi': scope.row.type === 3 ? 'ManagerListener': 'ClientApi'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="hostName" label="主机名"/>
      <el-table-column prop="host" label="主机URL"/>
      <el-table-column prop="port" label="端口"/>
      <el-table-column prop="path" label="Path"/>
      <el-table-column prop="mqTopic" label="消息队列topic"/>
      <el-table-column prop="mqQueue" label="消息队列queue"/>
      <el-table-column prop="mqExchange" label="消息队列exchange"/>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{scope.row.status === 0 ? '无效': '有效'}}</span>
        </template>
      </el-table-column>
      <el-table-column width="180px" align="center">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="detailsHandle(scope.row)"
          >详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <detailDialog @close-dialog="closeDialog" :dialogVisible='isDetailDialogShow' :connectData="viewRow"/>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/monitor/apiWatch/list/header'
  import detailDialog from '@/components/monitor/apiWatch/list/detailDialog'
  export default {
    name:'clientApiList',
    components: { eHeader,detailDialog },
    mixins: [initData],
    data() {
      return {
        viewRow:{},
        isDetailDialogShow:false
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = 'community/crm/getClientApiList'
        this.params = {
          page: this.page,
          size: this.size,
          idOrName: this.query.idOrName,
        };
        return true
      },
      queryDataHandle(){
        this.page = 0;
        this.init();
      },
      closeDialog(){
        this.viewRow={}
        this.isDetailDialogShow= false
      },
      detailsHandle(viewRow){
        this.viewRow = viewRow
        if(viewRow.type===2){
           this.$router.push({
            path: '/apiWatchDetails',
            query: {hostId: this.viewRow.id}
          })
        }else {
          this.isDetailDialogShow = true
        }

      }
    }
  }
</script>

<style scoped>

</style>
